const timeFormat = function(timeStr, type) {
	var time = new Date(timeStr);
	var y = time.getFullYear();
	var m = time.getMonth() + 1;
	var d = time.getDate();
	m = m < 10 ? '0' + m : m;
	d = d < 10 ? '0' + d : d;
	if (type == 'dateTime') {
		var h = time.getHours();
		var mm = time.getMinutes();
		var s = time.getSeconds();
		h = h < 10 ? '0' + h : h;
		mm = mm < 10 ? '0' + mm : mm;
		s = s < 10 ? '0' + s : s;
		return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':'+s
	} else if (type == 'date') {
		return y + '-' + m + '-' + d
	} else if (type == 'time') {
		var h = time.getHours();
		var mm = time.getMinutes();
		var s = time.getSeconds();
		h = h < 10 ? '0' + h : h;
		mm = mm < 10 ? '0' + mm : mm;
		s = s < 10 ? '0' + s : s;
		return  h + ':' + mm + ':'+s
	}
}
export default timeFormat